import {
    SoloEnteros,
    requiredSelect,
    onlyMayusTextEspecial,
    nullable
} from '@/_validations/validacionEspeciales'

import { 
    required, 
    minLength,
    maxLength,
    minValue,
    maxValue,
    numeric,
    alpha } from "vuelidate/lib/validators";

export default (value,status,web) => {
    let ordenTipo = {};
    let validationWeb = false;
    let validationIcon_Src = false;
    if (web) {
        validationWeb = { required };
        validationIcon_Src = {
            required,
            minLength: minLength(4),
            maxLength: maxLength(150)
        };
    }
    if(status){
        ordenTipo = {
            required,
            minValue: minValue(1),
            maxValue: maxValue(99),
            SoloEnteros,
        }
    }else if(status === false){
        ordenTipo = {
            required,
            minValue: minValue(0),
            maxValue: maxValue(99),
        }
    }
    if(value === 3){
        return {
            dataModule: {
                TpModuleId: {
                    required,
                },
                TpModuleNavegationId: validationWeb,
                TpVesselId: validationWeb,
                Icon: validationIcon_Src,
                OptionSrc: validationIcon_Src,
                GpoModuleNameEs: {
                    required,
                    onlyMayusTextEspecial,
                    minLength: minLength(4),
                    maxLength: maxLength(150)
                },
                GpoModuleNameEn: {
                    required,
                    onlyMayusTextEspecial,
                    minLength: minLength(4),
                    maxLength: maxLength(150)
                },
                description:{
                    minLength: minLength(4),
                    maxLength: maxLength(250),
                    onlyMayusTextEspecial,
                },
                orden: ordenTipo,
                nivel: {
                    numeric,
                    minValue: minValue(1),
                    maxValue: maxValue(3),
                    requiredSelect
                },
                padre:{
                    required
                },
            }
        }
    }
    else if(value === 2){
        return {
            dataModule: {
                TpModuleId: {
                    required,
                },
                TpModuleNavegationId: validationWeb,
                TpVesselId: validationWeb,
                Icon: validationIcon_Src,
                OptionSrc: validationIcon_Src,
                GpoModuleNameEs: {
                    required,
                    onlyMayusTextEspecial,
                    minLength: minLength(4),
                    maxLength: maxLength(150)
                },
                GpoModuleNameEn: {
                    required,
                    onlyMayusTextEspecial,
                    minLength: minLength(4),
                    maxLength: maxLength(150)
                },
                description:{
                    minLength: minLength(4),
                    maxLength: maxLength(250),
                    onlyMayusTextEspecial,
                },
                orden: ordenTipo,
                nivel: {
                    numeric,
                    minValue: minValue(1),
                    maxValue: maxValue(2),
                    requiredSelect
                },
                padre:{
                    required
                },
            }
        }
    }else{
        return {
            dataModule: {
                TpModuleId: {
                   required
                },
                TpModuleNavegationId: validationWeb,
                TpVesselId: validationWeb,
                Icon: validationIcon_Src,
                OptionSrc: validationIcon_Src,
                GpoModuleNameEs: {
                    required,
                    onlyMayusTextEspecial,
                    minLength: minLength(4),
                    maxLength: maxLength(150)
                },
                GpoModuleNameEn: {
                    required,
                    onlyMayusTextEspecial,
                    minLength: minLength(4),
                    maxLength: maxLength(150)
                },
                description:{
                    onlyMayusTextEspecial,
                    minLength: minLength(4),
                    maxLength: maxLength(250),
                },
                orden: ordenTipo,
                nivel: {
                    numeric,
                    minValue: minValue(1),
                    maxValue: maxValue(2),
                    requiredSelect
                },
                padre:{
                    
                },
            }
        }
    }
    
}